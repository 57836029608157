import { transFormWon } from "./numbers";

export const ESTIMATE_TYPE_MONTHLY_MOSILER = 1; // 견적서 타입 : 월간모시러
export const ESTIMATE_TYPE_DRIVER_DISPATCH = 2; // 견적서 타입 : 수행기사 파견

export const PAGE = {
	PAGE_01: 1,
	PAGE_02: 2,
	PAGE_03: 3,
	PAGE_04: 4,
	PAGE_05: 5,
	PAGE_06: 6,
	PAGE_07: 7,
	PAGE_08: 8,
	PAGE_09: 9,
	PAGE_10: 10,
	PAGE_11: 11,
	PAGE_12: 12,
	PAGE_13: 13,
	PAGE_14: 14,
	PAGE_15: 15,
	PAGE_16: 16,
	PAGE_17: 17,
	PAGE_18: 18,
	PAGE_19: 19,
	PAGE_20: 20,
};

export const KAKAO_CLIENT_ID = '9c8644cebd4d4307dd91d7678b7f66ca';

export const MAIN_REVIEW_SAMPLE_1 = {
	title: '“운전 뿐만 아니라<br>단순 업무도 대행해줘요”',
	reviewer: 'OOO 고객님 | VC 대표',
	contents: '출퇴근으로만 사용하던 수행기사 서비스를 공사 구분 없이 각종 단순업무를 대행해주니 일에만 더 집중할 수 있네요.',
	pattern: [
		{
			time: '07:00 AM',
			title: '조찬 미팅',
		},
		{ time: '09:00 AM', title: '광고 촬영현장 방문' },
		{
			time: '10:00 AM',
			title: 'Staff 간식 심부름',
		},
		{ time: '12:00 PM', title: '점심 약속' },
		{ time: '18:00 PM', title: '퇴근 운행' },
	],
};

export const MAIN_REVIEW_SAMPLE_2 = {
	title: '“개인 스케줄까지!<br>해결사 같은 서비스에요”',
	reviewer: 'OOO 고객님 | 스타트업 대표',
	contents: '아내의 심부름과 아이들을 돌봐줄 집사, 부모님 병원 동행에 주말에는 제 개인 스케줄까지 모든 걸 한 번에 해결해 준 서비스에요.',
	pattern: [
		{
			time: '07:00 AM',
			title: '출근 운행',
		},
		{ time: '08:00 AM', title: '채드윅 등교' },
		{
			time: '11:00 AM',
			title: '백화점 쇼핑 동행',
		},
		{ time: '14:00 PM', title: '간식 픽업' },
		{ time: '15:00 PM', title: '아이 하교/등원' },
		{ time: '17:00 PM', title: '퇴근 운행' },
	],
};

export const MAIN_QUESTION_LIST = [
	{
		title: '사용 시간이 추가될 경우 <br>요금은 어떻게 되나요?',
		contents: '계약한 월간 모시러 시간당 단가로 추가 사용 시간만큼 다음달 요금에 청구 드리고 있습니다.',
	},
	{
		title: '사용 못한 시간은 다음 달로 <br>이월되나요?',
		contents: '이월 가능합니다. 장기 계약 시 계약 기간 내에서 잔여시간을 이월시킬 수 있습니다.',
	},
	{
		title: '당일 사용도 가능한가요?',
		contents: '예약제로 진행되어 사용 전날 15시까지 알려주셔야 서비스 제공이 가능합니다. 하지만 무제한 상품의 경우 당일 사용이 가능합니다.',
	},
	{
		title: '시간을 나눠서 사용 <br>가능한가요?',
		contents: '운행 시작 시간을 기준으로 연속 사용하는 상품으로 나눠서 사용하실 경우 요금 조정/인상이 될 수 있습니다.',
	},
	{
		title: '파트너는 교체가 <br>가능한가요?',
		contents: '어떤 부분이든 원하시는 내용만 알려주세요. 요청사항에 부합하는 새로운 파트너로 교체가 가능합니다.',
	},

	{
		title: '운전만 제공되나요?',
		contents: '운전 뿐만 아니라 서류 발급 대행, 물품 구매 대행, 차량 정비 대행(정기 점검 등), 쇼핑 동행을 요청할 수 있으며, 집사 업무 범위는 계속 서비스 확장을 진행하고 있습니다.',
	},
];

export const QNA_SEARCH_KEYWORD_LIST = ['픽셀', '광고 검토 대기중', '계정 오류', '청구서', '결제', '요금'];
export const QNA_LIST = [
	{
		question: '월간모시러, 연간모시러의 차이 한눈에 비교하기',
		answer: '혹여나 억울한 상황이 발생 되지 않게 아래 세 가지 사항만 꼭 새겨두세요!',
	},
	{
		question: '드라이버가 고정 인가요?',

		answer: '차량 체크 영상은 꼭! 필요합니다. <br><br>간혹, 누가 했는지 모르는 차량 파손에 대하여 고객이 문의를 할 때가 있습니다. 이 때 고객에게 증빙 할 수 있는 자료는 파트너님께서 찍어주신 차량 영상 외엔 없습니다.  <br><br>혹여나 억울한 상황이 발생 되지 않게 아래 세 가지 사항만 꼭 새겨두세요!<br>1. 출발지에서 차량 체크 영상을 찍지 못했다면 최초 경유지에서 꼭! 촬영하기. <br>2. 차량 영상 찍을 땐 꼼꼼하게 파손된 부위 및 기스를 손가락으로 가르키기(휠 포함) <br>3. 촬영한 차량 체크 영상은 모시러 파트너 카카오톡으로 전송!<br><br>▼ 하단 영상을 참고해주세요!<br>',
	},
	{
		question: '운전 외 업무의 범위가 어디까지 가능한가요?',
		answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!',
	},
	{ question: '월/연간 모시러 이용(예약)은 어떻게 하나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '계약 프로세스가 어떻게 되나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '서비스 요금은 무엇이 포함되어 있나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '보험은 어떻게 운영되나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '이용 중 드라이버 교체를 희망할 경우 어떻게 신청 하나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '서비스 이용중 드라이버는 어디서 대기하나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '1회 최소 시간을 조정할 수 있나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '차량은 몇 대까지 이용할 수 있나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '차량이 같이 제공 되나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '추가 요금은 어떻게 산출되고 추가되나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '이용 고객이 지켜야 할 사항이 있나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
	{ question: '언제부터 이용할 수 있나요?', answer: '혹여나 억울한 상황이 발생되지 않게 아래 세가지 사항만 꼭 새겨두세요!' },
];

export const COMPARISON_ESTIMATE_OPTION_LIST = ['월간모시러 10일', '월간모시러 14일', '월간모시러 20일', '월간모시러 30일', '연간모시러 100시간', '연간모시러 200시간', '연간모시러 300시간'];

export const COMPARISON_ESTIMATE_OPTIONS_INFO = [
	{
		title: '월간모시러 10일',
		subscription: 'monthly',
		usageTime: 'day10',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '80시간',
			},
			{
				label: '드라이버 풀',
				value: '5명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: '26,000원',
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(26000*80),
			},
		],
	},
	{
		title: '월간모시러 14일',
		subscription: 'monthly',
		usageTime: 'day14',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '112시간',
			},
			{
				label: '드라이버 풀',
				value: '3명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(24000),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(24000 * 112),
			},
		],
	},
	{
		title: '월간모시러 20일',
		subscription: 'monthly',
		usageTime: 'day20',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '160시간',
			},
			{
				label: '드라이버 풀',
				value: '3명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(22500),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(22500 * 160),
			},
		],
	},
	{
		title: '월간모시러 30일',
		subscription: 'monthly',
		usageTime: 'day30',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '240시간',
			},
			{
				label: '드라이버 풀',
				value: '3명',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(21000),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(21000 * 240),
			},
		],
	},
	{
		title: '연간모시러 100시간',
		subscription: 'annual',
		usageTime: 'annual_1year_100h',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '100시간',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(26400),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(26400 * 100),
			},
		],
	},
	{
		title: '연간모시러 200시간',
		subscription: 'annual',
		usageTime: 'annual_1year_200h',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '200시간',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(25500),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(25500 * 200),
			},
		],
	},
	{
		title: '연간모시러 300시간',
		subscription: 'annual',
		usageTime: 'annual_1year_300h',
		info: [
			{
				label: '최소 이용 시간',
				value: '8시간',
			},
			{
				label: '기본 제공 시간',
				value: '300시간',
			},
			{
				label: '시간당 단가 (VAT별도)',
				value: transFormWon(23700),
			},
			{
				label: '이용 방법',
				value: '골프, 아이 픽업, 사택기사, 출퇴근',
			},
			{
				label: '총액',
				value: transFormWon(23700 * 300),
			},
		],
	},
];


// '연간모시러 100시간', '연간모시러 200시간', '연간모시러 300시간'];