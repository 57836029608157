import { useState } from 'react';
import '../../../styles/qna.css';
import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Input from '../../atoms/Input';
import { QNA_LIST, QNA_SEARCH_KEYWORD_LIST } from '../../../utils/constant';
import classNames from 'classnames';

const QnA = ({ setqnaOpen }) => {
	const [inputValue, setInputValue] = useState('');
	const [selectedSearchKeywords, setSelectedSearchKeywords] = useState([]);
	const [expandedIndex, setExpandedIndex] = useState(null);

	const handleInputChange = (e) => {
		setInputValue(e.target.value);
	};

	const handleKeywordClick = (key) => {
		setSelectedSearchKeywords((prev) => (prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]));
	};

	const toggleAccordion = (index) => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	const filteredQnA = QNA_LIST.filter(
		(qna) =>
			qna.question.toLowerCase().includes(inputValue.toLowerCase()) &&
			(selectedSearchKeywords.length === 0 || selectedSearchKeywords.some((keyword) => qna.question.toLowerCase().includes(keyword.toLowerCase())))
	);

	return (
		<div className='qna-wrap'>
			<div className='qna-header'>
				<span className='title'>도움말</span>
				<Button styleType={'icon'} classes={'close'} onClick={() => setqnaOpen(false)}>
					<Icon icon={'close'} />
				</Button>
			</div>
			<div className='qna-contents'>
				<div className='search-wrap'>
					<Icon icon={'search'} classes={'search-icon'} />
					<Input type='text' placeholder='월간 모시러' value={inputValue} onChange={handleInputChange} />
				</div>
				<div className='title-tag-list-wrap'>
					<span className='title'>관련 도움말을 보려면 주제를 선택하세요.</span>
					<ul className='keyword-list'>
						{QNA_SEARCH_KEYWORD_LIST.map((key) => (
							<li key={key} onClick={() => handleKeywordClick(key)} className={classNames('keyword', { selected: selectedSearchKeywords.includes(key) })}>
								{key}
							</li>
						))}
					</ul>
				</div>
				<div className='accordion-wrap'>
					<ul>
						{filteredQnA.map((qna, index) => (
							<li key={index} className='accordion-item'>
								<div className='accordion-title' onClick={() => toggleAccordion(index)}>
									{qna.question}
									<Icon icon={expandedIndex === index ? 'up' : 'down'} classes={'accordion-icon'} />
								</div>
								{expandedIndex === index && <div className='accordion-content' dangerouslySetInnerHTML={{ __html: qna.answer }}></div>}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default QnA;
